// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Sortable from "sortablejs"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
const UIkit = require("uikit")
const Icons = require('uikit/dist/js/uikit-icons')

UIkit.use(Icons)

window.UIkit = UIkit
window.Sortable = Sortable

document.addEventListener("DOMContentLoaded", () => {
  window.MDEditor = require('@toast-ui/editor')
})
